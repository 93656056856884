<template>
  <CRow>
    <Modal :modal="modal"></Modal>
    <CCol lg="12">
      <div class="d-flex align-items-center justify-content-end mb-3">
        <a href="javascript:void(0)" @click="$router.go(-1)">
          <CIcon name="cil-arrow-left" class="mr-2" />Voltar
        </a>
      </div>
      <CForm @submit.prevent="send">
        <FormCardTitle
          v-model="form"
          :title="`${isUpdate ? 'Editar' : 'Cadastrar'} Veículo`"
        />

        <Informations v-model="form" :processing="processing" />

        <BenefitInformations
          :medias="medias"
          v-model="form"
          :processing="processing"
        />

        <BenefitsIcons
          v-model="form.benefits"
          :icons="icons"
          :processing="processing"
          @update:value="(val) => (form.benefits = val)"
        />

        <DifferentialInformations
          :medias="medias"
          v-model="form"
          :processing="processing"
        />

        <DifferentialsIcons
          v-model="form.differentials"
          :icons="icons"
          :processing="processing"
          @update:value="(val) => (form.differentials = val)"
        />

        <Images v-model="medias" />
        <ImageGallery v-model="medias.medias" />

        <ColorVariation
          v-model="form.colors"
          :processing="processing"
          :medias="medias"
          @update:value="(val) => (form.colors = val)"
        />

        <Files v-model="medias" />
        <Seo v-model="form" :processing="processing" />

        <CRow v-if="isUpdate">
          <CCol sm="12">
            <CardLog :log="log" />
          </CCol>
        </CRow>

        <div class="d-flex justify-content-end align-items-center mt-4 mb-4">
          <a
            v-if="isUpdate"
            href="javascript:void(0)"
            class="mr-3"
            @click="resetForm"
          >
            Limpar Alterações
          </a>
          <CInputCheckbox
            v-else
            label="Criar outro"
            class="mr-3"
            :disabled="processing"
            @update:checked="setRedirect"
          />
          <CButton color="success" type="submit" :disabled="processing">
            {{ processing ? 'Salvando...' : 'Salvar' }}
          </CButton>
        </div>
      </CForm>
    </CCol>
  </CRow>
</template>

<script>
// services & plugins
import Service from '@/services/vehicles.service'
import IconService from '@/services/icons.service'
import moment from 'moment'
import { cloneDeep } from 'lodash'
import { joinListToSentense } from '@/utils'

// components
import CardLog from '@/components/ui/CardLog'
import Modal from '@/components/ui/Modal'
import FormCardTitle from '@/components/ui/Form/FormCardTitle'

// local components
import Images from './Images.vue'
import Informations from './Informations.vue'
import DifferentialInformations from './DifferentialInformations.vue'
import DifferentialsIcons from './DifferentialsIcons.vue'
import BenefitInformations from './BenefitInformations.vue'
import BenefitsIcons from './BenefitsIcons.vue'
import Files from './Files.vue'
import Seo from './Seo.vue'
import ImageGallery from './ImageGallery.vue'
import ColorVariation from './ColorVariation.vue'

const defaultItem = () => ({
  banner_id: '',
  banner_mobile_id: '',
  benefit_description: '',
  benefit_image_id: '',
  benefit_title: '',
  description: '',
  differential_description: '',
  differential_image_id: '',
  differential_title: '',
  file_id: '',
  main_image_id: '',
  model_id: '',
  seo_description: '',
  seo_id: '',
  seo_title: '',
  slug: '',
  status: true,
  subtitle: '',
  title: '',
  differentials: [],
  benefits: [],
  medias: [],
  colors: []
})

const defaultMedias = () => ({
  bannerDesktop: [],
  bannerMobile: [],
  mainImage: [],
  benefitImage: [],
  differentialImage: [],
  datasheet: [],
  medias: [],
  colorVariations: []
})

export default {
  metaInfo: {
    title: 'Veículos',
    titleTemplate: 'Manager - Rodonaves Iveco - %s'
  },

  components: {
    BenefitInformations,
    BenefitsIcons,
    CardLog,
    ColorVariation,
    DifferentialInformations,
    DifferentialsIcons,
    Files,
    FormCardTitle,
    ImageGallery,
    Images,
    Informations,
    Modal,
    Seo
  },

  data: () => ({
    item: defaultItem(),
    form: defaultItem(),
    medias: defaultMedias(),
    log: {},
    isLoading: true,
    processing: false,
    redirect: true,
    modal: {
      show: false,
      title: '',
      message: '',
      color: ''
    },
    icons: []
  }),

  async created() {
    this.setItem()
    this.getIcons()
  },

  computed: {
    isUpdate() {
      return !!this.$route.params.id
    }
  },

  methods: {
    async getIcons() {
      const { data } = await IconService.index({ pagination: false })
      this.icons = data.map(({ title, id }) => ({ value: id, label: title }))
    },
    async setItem() {
      const { id } = this.$route.params
      if (id) {
        const data = await Service.show(id)

        if (data) {
          const seo = data.seo ?? {}
          this.item = {
            ...this.item,
            ...data,
            slug: seo.url ?? '',
            seo_title: seo.title ?? '',
            seo_description: seo.description ?? '',
            colors: data.colors.map((color) => ({ ...color.pivot }))
          }

          this.medias = {
            bannerDesktop: [...data.banner],
            bannerMobile: [...data.banner_mobile],
            mainImage: [...data.main_image],
            benefitImage: [...data.benefit_image],
            differentialImage: [...data.differential_image],
            datasheet: [...data.file],
            medias: [...data.medias],
            colorVariations: data.colors.map((color) => [color])
          }

          this.log = {
            created_by: data.created ? data.created.name : 'Seed',
            created_at: moment(data.created_at).format('DD/MM/YYYY HH:mm'),
            updated_by: data.updated ? data.updated.name : null,
            updated_at: moment(data.updated_at).format('DD/MM/YYYY HH:mm')
          }
        }
      }

      this.form = cloneDeep(this.item)
    },

    resetForm() {
      this.medias = defaultMedias()
      this.item = defaultItem()
      this.form = defaultItem()
      this.setItem()
    },

    setRedirect(event) {
      this.redirect = !event
    },

    async send() {
      // const requiredMedias = {
      //   bannerDesktop: 'banner desktop',
      //   bannerMobile: 'banner mobile',
      //   mainImage: 'imagem principal',
      //   benefitImage: 'imagem benefícios',
      //   differentialImage: 'imagem diferenciais'
      // }

      // let mediaCheck = Object.keys(this.medias)
      //   .map((key) => !!this.medias[key].length || requiredMedias[key])
      //   .filter((item) => item !== true && !!item)

      // if (this.medias.colorVariations.length !== this.form.colors.length) {
      //   mediaCheck = [...mediaCheck, 'imagem da variação de cores']
      // }

      // if (!!mediaCheck.length) {
      //   const fields = joinListToSentense(mediaCheck)
      //   const sufix =
      //     mediaCheck.length > 1 ? 'são obrigatórios' : 'é obrigatório'

      //   this.modal = {
      //     show: true,
      //     title: 'Erro',
      //     message: `${fields} ${sufix}`,
      //     color: 'danger'
      //   }
      //   return
      // }

      this.processing = true

      const ordering = (data) =>
        data.map((item, index) => ({ ...item, order: index + 1 }))

      let response
      let message

      const medias = this.medias

      const { id } = this.$route.params
      const [bannerDesktop] = medias.bannerDesktop
      const [bannerMobile] = medias.bannerMobile
      const [mainImage] = medias.mainImage
      const [benefitImage] = medias.benefitImage
      const [differentialImage] = medias.differentialImage
      const [datasheet] = medias.datasheet

      const data = {
        ...this.form,
        id,
        banner_id: bannerDesktop?.id,
        banner_mobile_id: bannerMobile?.id,
        main_image_id: mainImage?.id,
        benefit_image_id: benefitImage?.id,
        differential_image_id: differentialImage?.id,
        file_id: datasheet?.id,
        medias: medias.medias.map(({ id }) => id),
        colors: this.form.colors.map((color, index) => {
          const [media] = medias.colorVariations[index]
          return { ...color, media_id: media.id }
        }),
        differentials: ordering(
          this.form.differentials.filter(({ differential }) => !!differential)
        ),
        benefits: ordering(
          this.form.benefits.filter(({ benefit }) => !!benefit)
        )
      }

      if (this.isUpdate) {
        response = await Service.update(id, data)
        message = 'Item foi atualizada com sucesso!'
      } else {
        response = await Service.store(data)
        message = 'Item foi cadastrada com sucesso!'
      }

      this.processing = false

      if (response.status === 200) {
        this.modal = {
          show: true,
          title: 'Sucesso',
          message: message,
          color: 'success',
          redirect: this.redirect
        }

        if (this.redirect) {
          this.resetForm()
          setTimeout(() => {
            this.$router.push(
              this.$route.matched[this.$route.matched.length - 2].path
            )
          }, 3000)
        } else {
          this.resetForm()
          setTimeout(() => {
            this.processing = false
            document.getElementById('first').focus()
            this.modal.show = false
          }, 1000)
        }
      } else if (response.status === 400) {
        this.modal = {
          show: true,
          title: 'Erro',
          message: this.manageErrorAlert(response),
          color: 'danger'
        }
      } else {
        this.modal = {
          show: true,
          title: 'Erro',
          message: 'Ocorreu um erro interno, por favor tente novamente!',
          color: 'danger'
        }
      }
    },
    manageErrorAlert(response) {
      const diffRegex =
        /required_if validation failed on differentials\.(?<item>.+)\.icon_id/g
      const benefitRegex =
        /required_if validation failed on benefits\.(?<item>.+)\.icon_id/g

      if (!response.data.messages.length)
        return 'Preencha todos os campos corretamente!'

      const messages = response.data.messages.map(({ message: msg }) => {
        const isBenefit = benefitRegex.test(msg)
        const isDifferential = diffRegex.test(msg)

        if (!isBenefit && !isDifferential) return msg

        const find = isDifferential ? diffRegex : benefitRegex
        const sufix = isDifferential ? 'diferenciais' : 'benefícios'
        const item = msg.replace(find, '$<item>')
        return `ícone ${parseInt(item) + 1} de ${sufix}`
      })

      return `Por favor preencha os campos: ${joinListToSentense(messages)}`
    }
  }
}
</script>
