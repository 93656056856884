<template>
  <FormCardItem title="Imagens">
    <CCol lg="12" v-for="(config, index) of imageConfig" :key="index">
      <FileUploader
        :id="index"
        :uploader-info="config.uploaderInfo"
        :enable-multiple="false"
        :enable-crop-on-uploader="config.crop"
        :enable-edit-on-api-table="false"
        :max-files="1"
        :accept-types="config.acceptTypes"
        :extensions="config.extensions"
        :medias="form[index]"
        :width="config.width"
        :height="config.height"
        :min-cropped-width="config.minCroppedWidth"
        :min-cropped-height="config.minCroppedHeight"
        :sizeFile="1024 * 1024 * config.sizeFile"
        uploader-type="banner"
      />
    </CCol>
  </FormCardItem>
</template>

<script>
import formFragmentMixin from '@/mixin/form-fragment'
import FileUploader from '@/components/ui/Fileuploader'
import FormCardItem from '@/components/ui/Form/FormCardItem'

const imageConfig = {
  bannerDesktop: {
    extensions: 'jpg,jpeg,png',
    acceptTypes: 'image/png,image/jpeg',
    width: 1920,
    height: 500,
    sizeFile: 5,
    crop: false,
    uploaderInfo: {
      label: 'Banner Desktop',
      recomendation: `O texto deve ficar em uma área centralizada com a largura de 1120px,
        <a class="text-primary" href="${process.env.BASE_URL}banner-desktop-example.jpg" download>veja o exemplo</a>`
    }
  },
  bannerMobile: {
    extensions: 'jpg,jpeg,png',
    acceptTypes: 'image/png,image/jpeg',
    width: 768,
    height: 400,
    sizeFile: 5,
    crop: false,
    uploaderInfo: {
      label: 'Banner Mobile',
      recomendation: `O texto deve ficar em uma área centralizada com a largura de 698px.
        <a class="text-primary" href="${process.env.BASE_URL}banner-mobile-example.jpg" download>veja o exemplo</a>`
    }
  },
  mainImage: {
    extensions: 'jpg,jpeg,png',
    acceptTypes: 'image/png,image/jpeg',
    minCroppedWidth: 360,
    minCroppedHeight: 248,
    sizeFile: 5,
    crop: true,
    uploaderInfo: {
      label: 'Imagem principal'
    }
  }
}

export default {
  mixins: [formFragmentMixin],
  components: { FileUploader, FormCardItem },
  data: () => ({ imageConfig })
}
</script>
