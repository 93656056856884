<template>
  <FormCardItem title="Benefícios Itens (ícones)">
    <template #title>
      <CCardHeader>
        <CRow class="align-items-center">
          <CCol sm="12" md="6">
            <h5 class="mb-0 d-flex align-items-center">
              <CIcon name="cil-justify-center" class="mt-md-1 mr-2" />
              Variação de cores
            </h5>
          </CCol>
          <CCol sm="12" md="6" class="card-header-actions">
            <CButton
              v-if="!!form.length"
              color="danger"
              class="float-right ml-2"
              @click="remove(null)"
            >
              <CIcon name="cil-trash" class="mr-1" />
              <span>Remover variação</span>
            </CButton>
            <CButton color="primary" class="float-right" @click="addItem">
              <CIcon name="cil-library-add" class="mr-2" />
              Adicionar variação
            </CButton>
          </CCol>
        </CRow>
      </CCardHeader>
    </template>
    <template>
      <CCol lg="12" v-for="(variation, index) in form" :key="index">
        <ColorVariationItem
          v-model="form[index]"
          :index="index"
          :processing="processing"
          :medias="medias.colorVariations[index]"
          @remove="remove"
        />
      </CCol>
      <CCol v-if="!form.length">
        <p class="mb-0 text-muted">
          Não há variações cadastradas,
          <a href="javascript:void(0)" class="text-info" @click="addItem">
            clique aqui
          </a>
          para adicionar uma nova variação.
        </p>
      </CCol>
    </template>
  </FormCardItem>
</template>

<script>
import formFragmentMixin from '@/mixin/form-fragment'
import ColorVariationItem from './ColorVariationItem.vue'
import FormCardItem from '@/components/ui/Form/FormCardItem'

const getDefaultColorVariation = () => ({ media_id: '', color: '' })

export default {
  components: { ColorVariationItem, FormCardItem },
  mixins: [formFragmentMixin],
  props: {
    processing: { type: Boolean, required: true },
    medias: { typer: Array, required: true }
  },

  methods: {
    remove(item) {
      this.form = this.form.filter((_, index) =>
        item === null ? false : index !== item
      )
    },
    addItem() {
      const formLength = this.form.length
      if (!this.medias.colorVariations[formLength]) {
        this.medias.colorVariations = [[], ...this.medias.colorVariations]
      }

      this.form = [getDefaultColorVariation(), ...this.form]
    }
  }
}
</script>
