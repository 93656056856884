<template>
  <CRow>
    <div class="gg-card mt-4">
      <cite class="gg-url"> https://www.example.com.br </cite>

      <h3 class="gg-title">
        {{ form.seo_title || 'Título da página' }}
      </h3>

      <span class="gg-desc">
        {{ stripTags(form.seo_description || 'Descrição da Página') }}
      </span>
    </div>
  </CRow>
</template>

<script>
import { stripTags } from '@/utils'

import formFragmentMixin from '@/mixin/form-fragment'
export default { mixins: [formFragmentMixin], methods: { stripTags } }
</script>

<style scoped lang="scss">
.gg-card {
  padding: 20px;
  -webkit-box-shadow: 0 0px 10px rgba(175, 175, 175, 0.3);
  box-shadow: 0 0px 10px rgba(175, 175, 175, 0.3);
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 2rem 1rem 0;

  .gg-title {
    color: #1a0dab;
    font-family: 'Arial', sans-serif;
    font-size: 1.5em;
    line-height: 26px;
    font-weight: 400;
    margin: 0px;
    margin-bottom: 5px;
  }

  .gg-url {
    color: rgba(60, 64, 67, 0.81);
    display: block;
    font-family: 'Arial', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    line-height: 18px;
    margin-bottom: 8px;
  }

  .gg-desc {
    font-family: 'Arial', sans-serif;
    font-size: 1em;
    display: block;
    line-height: 22px;
    color: #3c4043;
  }
}
</style>
