<template>
  <FormCardItem title="Informações">
    <CCol lg="4" class="mb-3">
      <label for="modelRelation">Modelo</label>
      <Multiselect
        :value="selectedModel"
        :options="models"
        @select="(selected) => (form.model_id = selected.value)"
      />
    </CCol>

    <CCol lg="4">
      <CInput
        id="first"
        v-model="form.title"
        label="Título*"
        placeholder="Título"
        required
        :disabled="processing"
      />
    </CCol>
    <CCol lg="4">
      <CInput
        v-model="form.subtitle"
        label="Subtítulo*"
        placeholder="Subtítulo"
        required
        :disabled="processing"
      />
    </CCol>
    <CCol col="12">
      <label for="description"> Descrição* </label>
      <TextEditor
        id="description"
        v-model="form.description"
        type="simpletext"
      />
    </CCol>
  </FormCardItem>
</template>

<script>
import ModelService from '@/services/models.service'
import formFragmentMixin from '@/mixin/form-fragment'

import TextEditor from '@/components/ui/TextEditor'
import Multiselect from '@/components/ui/Multiselect'
import FormCardItem from '@/components/ui/Form/FormCardItem'

export default {
  mixins: [formFragmentMixin],
  components: { Multiselect, TextEditor, FormCardItem },
  props: { processing: { type: Boolean, required: true } },
  data: () => ({ models: [] }),
  methods: {
    async getModels() {
      const { data } = await ModelService.index({
        paginate: false,
        perpage: false
      })
      this.models = data.map(({ title, id }) => ({ value: id, label: title }))
    }
  },
  created() {
    this.getModels()
  },
  computed: {
    selectedModel() {
      return this.models.find((model) => model.value == this.form.model_id)
    }
  }
}
</script>
