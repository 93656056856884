<template>
  <FormCardItem title="Benefícios Itens (ícones)">
    <template #title>
      <CCardHeader>
        <CRow class="align-items-center">
          <CCol sm="12" md="6">
            <h5 class="mb-0 d-flex align-items-center">
              <CIcon name="cil-justify-center" class="mt-md-1 mr-2" />
              Benefícios Itens (ícones)
            </h5>
          </CCol>
          <CCol sm="12" md="6" class="card-header-actions">
            <CButton
              v-if="!!form.length"
              color="danger"
              class="float-right ml-2"
              @click="remove(null)"
            >
              <CIcon name="cil-trash" class="mr-1" />
              <span>Remover todos benefícios</span>
            </CButton>
            <CButton
              :disabled="form.length >= 6"
              color="primary"
              class="float-right"
              @click="addItem"
            >
              <CIcon name="cil-library-add" class="mr-2" />
              Adicionar benefício
            </CButton>
          </CCol>
        </CRow>
      </CCardHeader>
    </template>
    <template>
      <CCol col="12" v-if="!!form.length">
        <DraggableComponent
          v-model="form"
          :removeButtonStyle="{ marginTop: '1.5rem' }"
        >
          <template v-slot="{ index }">
            <BenefitIconItem
              v-model="form[index]"
              :index="index"
              :icons="icons"
              :processing="processing"
              @remove="remove"
            />
          </template>
        </DraggableComponent>
      </CCol>
      <CCol v-if="!form.length">
        <p class="mb-0 text-muted">
          Não há benefícios cadastrados,
          <a href="javascript:void(0)" class="text-info" @click="addItem">
            clique aqui
          </a>
          para adicionar uma novo benefício.
        </p>
      </CCol>
    </template>
  </FormCardItem>
</template>

<script>
import formFragmentMixin from '@/mixin/form-fragment'
import FormCardItem from '@/components/ui/Form/FormCardItem'
import DraggableComponent from '@/components/ui/DraggableComponent'
import BenefitIconItem from './BenefitIconItem.vue'

const getDefaultBenefit = () => ({ icon_id: '', benefit: '' })

export default {
  components: { FormCardItem, DraggableComponent, BenefitIconItem },
  mixins: [formFragmentMixin],

  props: {
    processing: { type: Boolean, required: true },
    icons: { type: Array, required: true }
  },

  methods: {
    remove(item) {
      this.form = this.form.filter((_, index) =>
        item === null ? false : index !== item
      )
    },
    addItem() {
      this.form = [getDefaultBenefit(), ...this.form]
    }
  }
}
</script>
