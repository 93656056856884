<template>
  <CCard>
    <CCardHeader>
      <CRow class="align-items-center">
        <CCol>
          <b class="mb-0"> {{ `Variação ${index + 1}` }} </b>
        </CCol>
        <CCol>
          <div class="card-header-actions">
            <a
              href="javascript:void(0)"
              class="d-flex align-items-center text-danger"
              @click="$emit('remove', index)"
            >
              <CIcon name="cil-trash" />
              Remover
            </a>
          </div>
        </CCol>
      </CRow>
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol col="3">
          <CInput
            v-model="form.color"
            label="Cor da variação*"
            placeholder="Cor da variação"
            :disabled="processing"
            :required="form.media_id"
            type="color"
          />
        </CCol>
        <CCol lg="12">
          <FileUploader
            :id="`colorVariation${index}`"
            :uploader-info="imageConfig.uploaderInfo"
            :enable-multiple="true"
            :enable-crop-on-uploader="imageConfig.crop"
            :enable-edit-on-api-table="false"
            :max-files="1"
            :accept-types="imageConfig.acceptTypes"
            :extensions="imageConfig.extensions"
            :medias="medias"
            :min-cropped-width="imageConfig.width"
            :min-cropped-height="imageConfig.height"
            :sizeFile="1024 * 1024 * imageConfig.sizeFile"
            uploader-type="banner"
          />
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import formFragmentMixin from '@/mixin/form-fragment'
import FileUploader from '@/components/ui/Fileuploader'

const imageConfig = {
  extensions: 'jpg,jpeg,png',
  acceptTypes: 'image/png,image/jpeg',
  width: 665,
  height: 481,
  sizeFile: 5,
  crop: true,
  uploaderInfo: {
    label: 'Variação de cores'
  }
}

export default {
  mixins: [formFragmentMixin],
  props: {
    processing: { type: Boolean, required: true },
    index: { type: Number, required: true },
    medias: { typer: Array, required: true }
  },
  components: { FileUploader },
  data: () => ({ imageConfig })
}
</script>
