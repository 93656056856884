<template>
  <div>
    <TinyMce
      :id="id"
      v-model="editorValue"
      :api-key="'qwtvgxcwmati501oy9zae1rp0kqrubq1704bmhi325lte2gw'"
      :init="config"
      :disabled="disabled"
      name="textarea"
    />
  </div>
</template>

<script>
import TinyMce from '@tinymce/tinymce-vue'

export default {
  components: {
    TinyMce
  },

  props: {
    /**
     * Input ID property
     */
    id: {
      type: String,
      default: () => {
        return `texteditor-id-${Date.now()}`
      }
    },

    /**
     * Input value property
     */
    value: {
      type: String,
      default: ''
    },

    /**
     * Input disabled property
     */
    disabled: {
      type: Boolean,
      default: false
    },

    /**
     * Text editor type
     */
    type: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      editorValue: '',
      plugins: [],
      toolbar: [],
      config: {
        file_picker_types: 'image',
        plugins: [
          'autoresize',
          'autolink',
          'link',
          'media',
          'code',
          'lists',
          'paste',
          'image',
          'code'
        ],
        selector: 'textearea',
        automatic_upload: true,
        images_upload_url: `${process.env.VUE_APP_API_URL}/media-editor`,
        toolbar:
          'undo redo | styleselect | fontsizeselect | bold italic underline | alignleft aligncenter alignright alignjustify | numlist bullist | link | media | code | image',
        extended_valid_elements:
          'iframe[src|frameborder|style|scrolling|class|width|height|name|align],img[src|frameborder|style|scrolling|class|width|height|name|align]',
        valid_elements:
          'p[style],strong,b,em,span[style],a[href],ul,ol,li,br,&nbsp,h1,h2,h3,h4,h5,h6',
        valid_styles: {
          '*': 'font-size,text-align,font-weight,font-style,list-style'
        },
        fontsize_formats: '8px 10px 12px 14px 16px 18px 24px 30px 36px',
        content_style: 'body {font-size: 14px;}'
      }
    }
  },

  watch: {
    value: {
      handler() {
        this.editorValue = this.value
      }
    },

    editorValue() {
      this.$emit('input', this.editorValue)
    }
  },

  created() {
    this.setType()
  },

  mounted() {
    this.editorValue = this.value
  },

  methods: {
    setType() {
      if (this.type === 'simpletext') {
        this.config.toolbar = ['undo redo | bold italic underline | link']
      } else if (this.type === 'text') {
        this.config.toolbar = [
          'undo redo | styleselect | fontsizeselect | bold italic underline | alignleft aligncenter alignright alignjustify | numlist bullist | image'
        ]
      } else if (this.type === 'textWithOutImage') {
        this.config.toolbar = [
          'undo redo | styleselect | fontsizeselect | bold italic underline | alignleft aligncenter alignright alignjustify | numlist bullist'
        ]
      } else {
        this.config.toolbar = [
          'undo redo | styleselect | fontsizeselect | bold italic underline | alignleft aligncenter alignright alignjustify | numlist bullist | link | media | image | code'
        ]
      }
    }
  }
}
</script>

<style lang="scss">
.tox-tinymce {
  .tox-statusbar__branding {
    display: none;
  }
}
</style>
