<template>
  <div class="friendly-url">
    <CInput
      v-model="slug"
      :label="label"
      :placeholder="placeholder"
      :required="required"
      :disabled="disabled"
      @input="handleInput"
    />
    <CInputCheckbox
      label="Utilizar o nome/título como modelo para geração da URL Amigável"
      :inline="true"
      :custom="true"
      :checked="!edited"
      name="friendly-url-edited-flag"
      @update:checked="handleEditedFlag"
    />
  </div>
</template>

<script>
import slugify from 'slugify'
import { debounce } from 'lodash'
import SeoService from '@/services/seo.service'

export default {
  props: {
    value: {
      type: String,
      default: ''
    },

    basedOn: {
      type: String,
      default: ''
    },

    model: {
      type: String,
      required: true
    },

    field: {
      type: String,
      default: 'slug'
    },

    entity: {
      type: [String, Number],
      default: ''
    },

    label: {
      type: String,
      default: 'URL Amigável*'
    },

    placeholder: {
      type: String,
      default: 'URL Amigável'
    },

    required: {
      type: Boolean,
      default: true
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      slug: '',
      edited: false
    }
  },

  watch: {
    basedOn() {
      if (!this.edited) {
        this.slug = this.basedOn
      }
    },

    value() {
      this.slug = this.value
    },

    slug() {
      this.slugify()
    }
  },

  methods: {
    slugify() {
      if (!this.slug) return

      this.slug = slugify(this.slug, {
        replacement: '-',
        strict: true,
        remove: /[^a-zA-Z\d\s:-]/,
        lower: true
      })

      this.verifySlug()
    },

    verifySlug: debounce(async function () {
      try {
        const formData = {
          slug: this.slug,
          model: this.model,
          field: this.field
        }

        if (this.entity) {
          formData.entity = parseInt(this.entity)
        }

        const { data } = await SeoService.verifySlug(formData)
        const { slug } = data

        if (this.slug !== slug) {
          this.slug = slug
        }

        this.$emit('change', this.slug)
      } catch (error) {
        console.log('Houve um erro ao realizar a validaÃ§Ã£o da slug: ', error)
      }
    }, 700),

    handleInput() {
      this.$set(this, 'edited', true)
    },

    handleEditedFlag(checked) {
      if (checked) {
        this.edited = false
        this.slug = this.basedOn
      } else {
        this.edited = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.friendly-url {
  margin-bottom: 2rem;
}
</style>
