<template>
  <CModal
    :title="modal.title"
    :color="modal.color"
    :show.sync="modal.show"
    :centered="true"
    :close-on-backdrop="!modal.redirect"
    class="text-left modal-user"
    :class="{ 'modal-redirect': modal.redirect }"
  >
    <div class="modal-message" v-html="modal.message"></div>
    <div v-if="modal.redirect" class="modal-loading">
      <div class="modal-loading-spinner"></div>
      Aguarde, você será redirecionado...
    </div>
  </CModal>
</template>

<script>
export default {
  props: {
    modal: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="scss"></style>
