<template>
  <div class="draggable-component">
    <slot name="use-alert" v-if="list.length > 1">
      <p class="mb-1 text-muted">
        Clique e arraste o item na ordem que deseja.
      </p>
    </slot>
    <slot name="list">
      <CListGroup>
        <Draggable v-model="list">
          <CListGroupItem v-for="(item, index) in list" :key="index">
            <div class="draggable-component-container">
              <div class="draggable-component-item">
                <slot v-bind="{ item, index }"></slot>
              </div>
              <div class="draggable-component-actions">
                <slot name="actions">
                  <button
                    type="button"
                    class="text-danger draggable-component-remove"
                    :style="removeButtonStyles"
                    @click="remove(index)"
                  >
                    <CIcon name="cil-trash" />
                    <span> Remover </span>
                  </button>
                </slot>
              </div>
            </div>
          </CListGroupItem>
        </Draggable>
      </CListGroup>
    </slot>
  </div>
</template>

<script>
import Draggable from 'vuedraggable'

export default {
  components: { Draggable },
  props: {
    value: { type: Array, default: () => [] },
    removeButtonStyle: { type: Object, default: () => ({}) }
  },
  methods: {
    remove(indexToRemove) {
      const values = this.list.filter((_, index) => index !== indexToRemove)
      this.$emit('input', [...values])
    }
  },
  computed: {
    list: {
      get() {
        return [...this.value]
      },
      set(value) {
        this.$emit('input', [...value])
      }
    },
    removeButtonStyles() {
      const style = {
        ...this.removeButtonStyle
      }
      return style
    }
  }
}
</script>

<style lang="scss" scoped>
.draggable-component {
  &-actions {
    width: 5rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  &-container {
    width: 100%;
    display: flex;
    align-items: center;
    cursor: move;
  }

  &-item {
    margin-right: 1rem;
    width: calc(100% - 6rem);
  }

  &-remove {
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    transition: 0.3s;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:hover {
      color: #ff3924 !important;
    }
  }
}
</style>
