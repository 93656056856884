import vue from 'vue'

export default vue.extend({
  props: {
    value: { type: [Object, Array], required: true }
  },

  computed: {
    form: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('update:value', val)
      }
    }
  }
})
