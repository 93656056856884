<template>
  <FormCardItem title="Diferenciais">
    <CCol lg="12">
      <FileUploader
        id="differentialImage"
        :uploader-info="uploaderInfo"
        :enable-multiple="false"
        :enable-crop-on-uploader="true"
        :enable-edit-on-api-table="false"
        :max-files="1"
        accept-types="image/png,image/jpeg"
        extensions="jpg,jpeg,png"
        :medias="medias.differentialImage"
        :min-cropped-height="456"
        :min-cropped-width="550"
        :sizeFile="1024 * 1024 * 5"
        uploader-type="banner"
      />
    </CCol>
    <CCol lg="6">
      <CInput
        v-model="form.differential_title"
        label="Título*"
        placeholder="Título"
        required
        :disabled="processing"
      />
    </CCol>
    <CCol lg="6">
      <CInput
        v-model="form.differential_description"
        label="Descrição*"
        placeholder="Descrição"
        required
        :disabled="processing"
      />
    </CCol>
  </FormCardItem>
</template>

<script>
import formFragmentMixin from '@/mixin/form-fragment'
import FileUploader from '@/components/ui/Fileuploader'
import FormCardItem from '@/components/ui/Form/FormCardItem'

const uploaderInfo = {
  label: 'Imagem'
}

export default {
  mixins: [formFragmentMixin],
  props: {
    processing: { type: Boolean, required: true },
    medias: { typer: Array, required: true }
  },
  components: { FileUploader, FormCardItem },
  data: () => ({ uploaderInfo })
}
</script>
