<template>
  <CCard class="card-log mb-0">
    <CCardHeader
      class="btn text-left d-flex align-items-center justify-content-between"
      :style="
        cardCollapse
          ? 'box-shadow: none !important; border-radius: calc(0.25rem - 1px);'
          : ''
      "
      @click="cardCollapse = !cardCollapse"
    >
      <span>
        <CIcon name="cil-notes" class="mr-1" />
        Dados do registro
      </span>

      <CIcon
        name="cil-chevron-circle-down-alt"
        class="chevron-arrow"
        :class="{ 'chevron-arrow-up': !cardCollapse }"
      />
    </CCardHeader>
    <CCollapse :show="!cardCollapse">
      <CCardBody>
        <CRow>
          <CCol v-if="log.created_by" col="12" sm="6" class="card-log-created">
            <p>
              <b>Criado</b>
            </p>

            <p class="mb-0">
              <b>Por:</b> {{ log.created_by }}<br />
              <b>Em:</b> {{ log.created_at }}
            </p>
          </CCol>
          <CCol v-if="log.updated_by" col="12" sm="6" class="card-log-updated">
            <p>
              <b>Atualizado</b>
            </p>

            <p class="mb-0">
              <b>Por:</b> {{ log.updated_by }}<br />
              <b>Em:</b> {{ log.updated_at }}
            </p>
          </CCol>
        </CRow>
      </CCardBody>
    </CCollapse>
  </CCard>
</template>

<script>
export default {
  props: {
    /**
     * Log object
     * @arg created_by @type {String} Name of who created
     * @arg created_at @type {Timestamp} Creation date
     * @arg updated_by @type {String} Name of who modified
     * @arg updated_at @type {Timestamp} Modified date
     */
    log: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      cardCollapse: true
    }
  }
}
</script>

<style lang="scss">
.card-log {
  background-color: rgba(255, 255, 255, 0.3);
  border-color: #e0e0e0;

  .btn:not([class*='ghost']):not([class*='link']):not([class*='outline']):not([class*='transparent']):not([class*='dropdown-toggle']):not([class*='search-component-button']) {
    box-shadow: 0 1px 1px 0 rgba(60, 75, 100, 0.14) !important;
  }

  .card-header {
    border: 0px;
    padding: 0.75rem 1.25rem !important;
    background-color: rgba(255, 255, 255, 0.3);

    .chevron-arrow {
      transition: 700ms ease;

      &.chevron-arrow-up {
        transform: rotate(180deg);
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .card-log {
    .card-log-created {
      margin-bottom: 1rem;
    }
  }
}
</style>
