<template>
  <FormCardItem title="Arquivos">
    <CCol lg="12" v-for="(config, index) of filesConfig" :key="index">
      <FileUploader
        :id="index"
        :uploader-info="config.uploaderInfo"
        :enable-multiple="false"
        :enable-crop-on-uploader="false"
        :enable-edit-on-api-table="false"
        :max-files="1"
        :accept-types="config.acceptTypes"
        :extensions="config.extensions"
        :medias="form[index]"
        :sizeFile="1024 * 1024 * config.sizeFile"
        uploader-type="file"
      />
    </CCol>
  </FormCardItem>
</template>

<script>
import formFragmentMixin from '@/mixin/form-fragment'
import FileUploader from '@/components/ui/Fileuploader'
import FormCardItem from '@/components/ui/Form/FormCardItem'

const filesConfig = {
  datasheet: {
    extensions: 'pdf',
    acceptTypes: 'application/pdf',
    sizeFile: 5,
    uploaderInfo: {
      label: 'Ficha tecnica'
    }
  }
}

export default {
  mixins: [formFragmentMixin],
  components: { FileUploader, FormCardItem },
  data: () => ({ filesConfig })
}
</script>
