<template>
  <CCard>
    <slot name="title" v-bind="$props">
      <CCardHeader class="d-flex justify-content-between align-items-center">
        <h5 class="mb-0 d-flex align-items-center">
          <CIcon name="cil-justify-center" class="mt-md-1 mr-2" />
          {{ title }}
        </h5>
      </CCardHeader>
    </slot>

    <CCardBody>
      <CRow>
        <slot />
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  props: { title: { type: String, required: true } }
}
</script>
