<template>
  <FormCardItem title="Galeria de imagens">
    <CCol lg="12">
      <FileUploader
        id="imageGallery"
        :uploader-info="galleryConfig.uploaderInfo"
        :enable-multiple="true"
        :enable-crop-on-uploader="galleryConfig.crop"
        :enable-edit-on-api-table="false"
        :max-files="20"
        :accept-types="galleryConfig.acceptTypes"
        :extensions="galleryConfig.extensions"
        :medias="form"
        :min-cropped-height="galleryConfig.height"
        :min-cropped-width="galleryConfig.width"
        :sizeFile="1024 * 1024 * galleryConfig.sizeFile"
        uploader-type="banner"
      />
    </CCol>
  </FormCardItem>
</template>

<script>
import formFragmentMixin from '@/mixin/form-fragment'
import FileUploader from '@/components/ui/Fileuploader'
import FormCardItem from '@/components/ui/Form/FormCardItem'

const galleryConfig = {
  extensions: 'jpg,jpeg,png',
  acceptTypes: 'image/png,image/jpeg',
  width: 930,
  height: 851,
  sizeFile: 5,
  crop: true,
  uploaderInfo: {
    label: 'Galeria de imagens'
  }
}
export default {
  mixins: [formFragmentMixin],
  components: { FileUploader, FormCardItem },
  data: () => ({ galleryConfig })
}
</script>
