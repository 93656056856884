<template>
  <CCard>
    <CCardHeader class="d-flex justify-content-between align-items-center">
      <slot>
        <slot name="title">
          <h4 class="mb-0">
            {{ title }}
          </h4>
        </slot>
        <slot name="append">
          <div class="d-flex align-items-center">
            <slot name="after-status"></slot>
            <slot name="status">
              <div class="ml-2 d-flex align-items-center">
                <label class="mb-0">
                  <b>Status</b>
                </label>
                <CSwitch
                  type="checkbox"
                  color="success"
                  variant="3d"
                  class="mx-2"
                  v-bind="statusIcon"
                  :checked.sync="form.status"
                />
              </div>
            </slot>
          </div>
        </slot>
      </slot>
    </CCardHeader>
  </CCard>
</template>

<script>
import formFragmentMixin from '@/mixin/form-fragment'

export default {
  props: { title: { type: String, required: true } },
  mixins: [formFragmentMixin],
  data: () => ({
    statusIcon: {
      labelOn: '\u2713',
      labelOff: '\u2715'
    }
  })
}
</script>
