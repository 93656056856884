<template>
  <FormCardItem title="SEO">
    <CCol lg="6">
      <FriendlyUrl
        :value="form.slug"
        :based-on="form.title"
        :entity="$route.params.id"
        field="slug"
        model="vehicles"
        @change="(value) => (form.slug = value)"
      />
    </CCol>
    <CCol lg="6" class="mb-4">
      <CInput
        v-model="form.seo_title"
        label="Título da página"
        placeholder="Digite o título da página"
        :maxlength="titleLength"
        :required="true"
      />
      <CInputCheckbox
        label="Utilizar o nome/título deste registro"
        :inline="true"
        :custom="true"
        :checked="checkedTitle"
        @update:checked="handleCheck($event, 'seo_title', 'title', titleLength)"
      />
      <small class="text-muted float-right">
        {{ `${form.seo_title.length}/${titleLength} caracteres` }}
      </small>
    </CCol>
    <CCol col="12">
      <CTextarea
        v-model="form.seo_description"
        label="Descrição da página"
        placeholder="Digite a descrição da página"
        :maxlength="descriptionLength"
      />

      <CInputCheckbox
        label="Utilizar mesmo texto/descrição deste registro"
        :inline="true"
        :custom="true"
        :checked="checkedDescription"
        @update:checked="
          handleCheck(
            $event,
            'seo_description',
            'description',
            descriptionLength
          )
        "
      />
      <small class="text-muted float-right">
        {{ `${form.seo_description.length}/${descriptionLength} caracteres` }}
      </small>
    </CCol>
    <CCol col="12">
      <SeoPreview v-model="form" />
    </CCol>
  </FormCardItem>
</template>

<script>
import FriendlyUrl from '@/components/ui/FriendlyUrl'
import FormCardItem from '@/components/ui/Form/FormCardItem'
import SeoPreview from '@/components/ui/SeoPreview.vue'

import formFragmentMixin from '@/mixin/form-fragment'
import { stripTags } from '@/utils'

export default {
  mixins: [formFragmentMixin],
  components: { FriendlyUrl, FormCardItem, SeoPreview },
  data: () => ({
    titleLength: 70,
    descriptionLength: 160,
    checkedTitle: true,
    checkedDescription: true
  }),
  methods: {
    handleCheck(checked, target, origin, len) {
      if (origin === 'title') this.checkedTitle = checked
      if (origin === 'description') this.checkedDescription = checked
      if (!checked) return
      this.form[target] = stripTags(
        this.convertHtmlToText(this.form[origin])
      ).substring(0, len)
    },
    convertHtmlToText(text) {
      let txt = document.createElement('textarea')
      txt.innerHTML = text
      return txt.value
    },
    isChecked(target, origin, len) {
      const checked =
        stripTags(this.form[target]) ===
        stripTags(this.form[origin]).substring(0, len)

      return checked
    }
  },
  watch: {
    'form.title'() {
      this.handleCheck(
        this.checkedTitle,
        'seo_title',
        'title',
        this.titleLength
      )
    },
    'form.description'() {
      this.handleCheck(
        this.checkedDescription,
        'seo_description',
        'description',
        this.descriptionLength
      )
    },
    'form.id'(val) {
      if (!val) return
      this.checkedTitle = this.isChecked('seo_title', 'title', this.titleLength)
      this.checkedDescription = this.isChecked(
        'seo_description',
        'description',
        this.descriptionLength
      )
    }
  }
}
</script>
